import { useState, useRef } from "react";

export const useAnchorMenu = (initialAnchorEl = null) => {
  const [rowClicked, setRowClicked] = useState(false);
  const withRowClicked = fn => () => {
    fn();
    setRowClicked(!rowClicked);
  };

  const buttonRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(initialAnchorEl);

  const openMenu = withRowClicked(() => setAnchorEl(buttonRef.current));
  const closeMenu = withRowClicked(() => setAnchorEl(null));
  const toggleMenu = withRowClicked(() => (anchorEl ? closeMenu() : openMenu()));

  const isOpen = !!anchorEl;

  return {
    anchorEl,
    buttonRef,
    toggleMenu,
    openMenu,
    closeMenu,
    isOpen,
    rowClicked,
  };
};
