import { camelCase } from "lodash";
import useUsuario from "./useUsuario";

function useCustomPermission(customPermission) {
  const { featureToggles } = useUsuario();
  if (featureToggles && customPermission !== "" && customPermission !== null && customPermission !== undefined) {
    return featureToggles[camelCase(customPermission)];
  }

  return false;
}

export default useCustomPermission;
