import { useEffect } from "react";
import useSWR from "swr";

const URL = "/api/pipeline/lead/Semelhantes";
function useLeadsSemelhantes(campo, valor, leadId = null, ddi = null) {
  const { data: leads, isValidating: loading, error, mutate } = useSWR(
    () => {
      if (valor !== "" && campo !== "lead_site") {
        if (["contato_telefone", "lead_telefone"].includes(campo) && ddi && valor === ddi) {
          return null;
        }

        return getUrl(valor);
      }

      const match = valor
        .replace(".site", "")
        .replace(/\\/g, "")
        .match(/(?:https?:\/\/)?(?:www\.)?([^/?#]+)/);
      if (campo === "lead_site" && match[1].length > 3) {
        const result = match ? match[1] : "";
        return getUrl(result);
      }

      return null;
    },
    {
      revalidateOnMount: true,
      fallbackData: [],
    }
  );

  function getUrl(result) {
    return `${URL}?campo=${campo}&valor=${encodeURIComponent(result)}&id=${leadId || null}`;
  }

  useEffect(
    () => {
      if (valor && campo) mutate();
      else mutate([]);
    },
    [valor, fetch]
  );

  return [leads, loading, error];
}

export default useLeadsSemelhantes;
