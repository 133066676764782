import { useEffect, useState } from "react";
import { debounce } from "lodash";

function usePaginatedList(fetchFn, args, funilId) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({ current: 0 });
  const [content, setContent] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  function fetch(reset) {
    if (reset) {
      setPage({ current: 0 });
      setContent([]);
    } else setPage(({ current }) => ({ current: current + 1 }));
  }

  useEffect(
    () => {
      fetch(true);
    },
    [args, funilId]
  );

  useEffect(
    () => {
      if (loading) return;
      setLoading(true);
      fetchFn({ ...args, page: page.current }).then(data => {
        setLoading(false);
        setHasMore(!!data.length);
        setContent(c => [...c, ...data]);
      });
    },
    [page]
  );

  return [content, debounce(fetch, 500), loading, hasMore, page.current];
}

export default usePaginatedList;
