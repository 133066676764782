import { useEffect, useState } from "react";
import axios from "axios";

const cache = {};

function useCachedList(url, filter = x => x) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  function forceReload() {
    cache[url] = undefined;
    return fetch();
  }

  async function fetch() {
    if (!cache[url]) {
      cache[url] = axios.get(url);
      setLoading(true);
    }
    const { data } = await cache[url];
    setItems(data.filter(filter));
    setLoading(false);
  }

  useEffect(() => {
    fetch();
    return () => {
      cache[url] = undefined;
    };
  }, []);

  return [items, loading, forceReload];
}

export default useCachedList;
