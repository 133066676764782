import { useMemo } from "react";
import { sortBy, reverse } from "lodash";

export default function useSorted(items = [], field, ascending) {
  return useMemo(
    () => {
      const sorted = sortBy(items, [field]);
      if (!ascending) return reverse(sorted);
      return sorted;
    },
    [items, field, ascending]
  );
}
