import { useState, useEffect } from "react";
import localStorageManager from "../_common/utils/storage";

const useLocalStorageListener = key => {
  const [value, setValue] = useState(() => !!localStorageManager.getItem(key));

  useEffect(
    () => {
      const handleStorageChange = () => {
        setValue(!!localStorageManager.getItem(key));
      };

      window.addEventListener("storageChange", handleStorageChange);

      return () => {
        window.removeEventListener("storageChange", handleStorageChange);
      };
    },
    [key]
  );

  return value;
};

export default useLocalStorageListener;
