import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import isLogado from "../_common/utils/auth/auth";
import { IsUsuarioLogado } from "../store/reducers/usuario/usuarioActions";
import routes from "../_common/components/MainRoutes/constantes";

const useAuthVerification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const estaLogadoAction = estaLogado => IsUsuarioLogado(dispatch, estaLogado);

  const [usuarioLogado, setUsuarioLogado] = useState(null);
  const location = useLocation();

  useEffect(
    () => {
      const verificarLogin = async () => {
        const logado = isLogado();
        if (logado) {
          setUsuarioLogado(() => true);
          estaLogadoAction(true);
        } else {
          setUsuarioLogado(() => false);
          estaLogadoAction(false);

          const PUBLIC_BASENAME = "/public/";
          const FEEDBACK_BASENAME = "/reuniao/";
          const CONFERENCIA_BASENAME = "/conferencia/";
          const estaNoPublic = () =>
            window.location.href.includes(PUBLIC_BASENAME) ||
            window.location.href.includes(FEEDBACK_BASENAME) ||
            window.location.href.includes(CONFERENCIA_BASENAME);

          if (!window.location.pathname.includes("Account") && !estaNoPublic()) history.push(routes.LOGIN.route);
        }
      };

      verificarLogin();
    },
    [location.pathname]
  );

  return usuarioLogado;
};

export default useAuthVerification;
