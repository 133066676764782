import { useEffect } from "react";
import { useSelector } from "react-redux";

import { loadAppInsights } from "../_common/utils/startUpApplication/analyticsProvider/ApplicationInsightsService";
import localStorageManager from "../_common/utils/storage";
import { INFOS_USUARIO } from "../_common/utils/storage/constantesStorage";
import { selectUsuarioEstaLogado } from "../store/reducers/usuario/selectors";
import env from "./src/../../environment";

export function useAppInsightsLoader() {
  const usuarioEstaLogado = useSelector(selectUsuarioEstaLogado);

  const storedUserInfo = localStorageManager.getItem(INFOS_USUARIO);
  const { estaNoAmbienteDeDesenvolvimento } = storedUserInfo || {};

  useEffect(
    () => {
      if (
        usuarioEstaLogado &&
        env.HABILITA_APP_INSIGHT &&
        !estaNoAmbienteDeDesenvolvimento &&
        estaNoAmbienteDeDesenvolvimento !== undefined &&
        estaNoAmbienteDeDesenvolvimento !== null
      ) {
        loadAppInsights();
      }
    },
    [usuarioEstaLogado]
  );
}
