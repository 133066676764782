import { useEffect } from "react";

export default function usePageTitle(text) {
  useEffect(
    () => {
      document.title = text;
    },
    [text]
  );
}
