import { useCallback } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { AjaxBlackout, createSnackbar, createSnackbarAPIException } from "../_common";
import { currencyToNumber } from "../_common/utils/currency";

const useProdutosSubmit = (id, lista, mutate, mutateLista, onSuccess) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const salvar = useCallback(
    async p => {
      await axios.post(`/api/pipeline/lead/produtos?id=${id}`, { produtos: p }).then(
        () =>
          mutate &&
          mutate(
            p.map(product => {
              const equivalente = lista.find(produto => produto.id === product.id);
              return { ...product, descricao: equivalente.descricao, valor: equivalente.valor };
            }),
            false
          )
      );
    },
    [lista]
  );

  const onSubmit = useCallback(
    async ({ produtos: prods }) => {
      /*
     * A `descricao` é removida do produto antes de enviar para o back
     * pois seu valor não é atualizado de forma consistente pelo Formik.
     * Dessa forma, pode acabar confundindo durante o debug.
     */
      const unformatted = prods.map(({ desconto, valor, descricao, ...produto }) => ({
        ...produto,
        valor: typeof valor === "string" ? currencyToNumber(valor || "") : valor,
        desconto: typeof desconto === "string" ? currencyToNumber(desconto || "") : desconto,
      }));

      try {
        AjaxBlackout.Show();
        await salvar(unformatted);
        createSnackbar(intl.formatMessage({ defaultMessage: "Produtos salvos com sucesso." }));
        document.dispatchEvent(
          new CustomEvent("detalhe-lead/timeline/reloadLogFilterTriggers", { detail: { flag: true } })
        );
        if (onSuccess) onSuccess();
      } catch (err) {
        createSnackbarAPIException(err);
      } finally {
        AjaxBlackout.Hide();
        if (mutate) mutate();
        if (mutateLista) mutateLista();
      }
    },
    [salvar, intl, dispatch, mutate, mutateLista]
  );

  return { onSubmit };
};

export default useProdutosSubmit;
