import { useEffect } from "react";
import useSWR from "swr";

const URL = "/api/pipeline/lead/SemelhantesBloqueados";

function useLeadsSemelhantesBloqueados(campo, valor, tipoMensageiro = null, ddi = null) {
  const url = `${URL}?campo=${campo}&valor=${encodeURIComponent(valor)}&tipoMensageiro=${tipoMensageiro || null}`;

  const { data, isValidating, error, mutate, ...restSWRResponse } = useSWR(
    () => {
      if (valor && valor !== "") {
        if (["contato_telefone", "lead_telefone"].includes(campo) && ddi && valor === ddi) {
          return null;
        }

        return url;
      }

      return null;
    },
    {
      fallbackData: false,
    }
  );

  useEffect(
    () => {
      if (!valor) mutate(false, false);
    },
    [valor]
  );

  return { data, isValidating, error, mutate, ...restSWRResponse };
}

export default useLeadsSemelhantesBloqueados;
