import { useEffect, useRef } from "react";

export default function useEvent(eventName, handler, element = document) {
  const savedHandler = useRef();

  useEffect(
    () => {
      savedHandler.current = handler;
    },
    [handler]
  );

  useEffect(
    () => {
      const isSupported = element && element.addEventListener;
      if (!isSupported) return undefined;
      if (!eventName) return undefined;

      const eventListener = event => {
        if (typeof savedHandler.current === "function") {
          savedHandler.current(event);
        }
      };

      element.addEventListener(eventName, eventListener);

      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element]
  );
}
