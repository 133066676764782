import { useEffect } from "react";
import Highcharts from "highcharts";

const defaults = {
  chart: {
    type: "pie",
    spacingTop: 20,
  },
  legend: {
    align: "right",
    verticalAlign: "top",
    itemMarginBottom: 25,
  },
  title: {
    text: "",
  },
  yAxis: {
    allowDecimals: false,
    min: 0,
    title: {
      text: "Quantidade",
    },
  },
  plotOptions: {
    column: {
      stacking: "normal",
    },
  },
  credits: {
    enabled: false,
    text: "",
  },
};

function useHighchart(id, options) {
  useEffect(
    () => {
      if (document.getElementById(id)) Highcharts.chart(id, { ...defaults, ...options });
    },
    [id, options]
  );
}

export default useHighchart;
