// @ts-check

import { useState, useEffect } from "react";

import { filterByTermInProps } from "../_common/utils/fp";
import useDebounce from "./useDebounce";

/**
 * Hook para filtrar um array de objetos de acordo com a digitação de um termo de busca.
 * É possível buscar pela string em múltiplas propriedades do objeto, basta passar os nomes
 * das propriedades do objeto que se deseja buscar no parametro `searchInProps`.
 * 
 * Ideal para quando o filtro de lista é aplicado **exclusivamente no front-end**.

 * @example
 * // Para considerar os campos `nome` e `email` na busca
 * searchInProps = ["nome", "email"]
 * 
 * @param {array} originalData - Array de objetos original
 * @param {array} searchInProps - Array contendo o nome das propriedades do objeto que se deseja procurar
 * @param {number} [debounceDelay=300] - Delay aplicado ao debounce
 */
export default function useFiltered(originalData, searchInProps, debounceDelay = 300) {
  const [filteredData, setFilteredData] = useState(originalData);
  const [searchTerm, setSearchTerm] = useState("");

  const debouncedSearch = useDebounce(searchTerm, debounceDelay);

  useEffect(
    // eslint-disable-next-line consistent-return
    () => {
      if (!originalData?.length) {
        setFilteredData(originalData);
        setSearchTerm("");
        return undefined;
      }

      if (debouncedSearch) {
        const filterFn = filterByTermInProps(debouncedSearch)(searchInProps);
        setFilteredData(filterFn(originalData));
      } else {
        setFilteredData(originalData);
      }
    },
    [debouncedSearch, originalData, searchInProps]
  );

  return {
    filteredData,
    searchTerm,
    setSearchTerm,
  };
}
