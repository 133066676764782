import { CachedRequests } from "./_common/utils/cachedRequests";

const { default: localStorageManager } = require("./_common/utils/storage");
const { ENV } = require("./_common/utils/storage/constantesStorage");

function getEnvironment() {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", `${window.location.origin}/environmentVariables.json`, false);
  xhr.send(null);

  if (xhr.status === 200) {
    try {
      const envBuild = JSON.parse(xhr.responseText);
      return envBuild;
    } catch (error) {
      console.error("Erro ao fazer o parse do arquivo environmentVariables.json:", error);
      throw new Error(error);
    }
  } else {
    console.error("Erro ao carregar environmentVariables.json");
    throw new Error("Erro ao carregar environmentVariables.json");
  }
}

export const loadEnvironment = () => {
  const SEGUNDOS_CACHE = 60;
  const MINUTOS_CACHE = 240;
  const TEMPO_EM_SEGUNDOS_CACHE = MINUTOS_CACHE * SEGUNDOS_CACHE;

  const envJson = CachedRequests.getSync(ENV, () => getEnvironment(), { maxAge: TEMPO_EM_SEGUNDOS_CACHE });
  const env = typeof envJson === "object" ? envJson : JSON.parse(envJson);
  localStorageManager.setItem(ENV, env);
  return env;
};

// eslint-disable-next-line no-return-await
const env = loadEnvironment();
export default env;
