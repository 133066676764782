import { useState, useEffect } from "react";
import axios from "axios";

function useLista(url, onLoadData = null) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const updateItemLista = item => {
    if (items.some(i => i.id === item.id)) {
      setItems(items.map(itemVelho => (itemVelho.id === item.id ? { ...item } : itemVelho)));
    } else setItems([...items, item]);
  };

  useEffect(
    () => {
      const token = axios.CancelToken;
      const source = token.source();
      const cancel = () => source.cancel();
      if (url) {
        setLoading(true);
        axios
          .get(url, { cancelToken: source.token })
          .then(response => {
            const data = typeof onLoadData === "function" ? onLoadData(response.data) : response.data;
            setItems(data);
          })
          .then(() => setLoading(false))
          .catch(cancel);
      }
      return cancel;
    },
    [url]
  );

  return [items, loading, updateItemLista, setItems];
}

export default useLista;
