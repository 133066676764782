import { useEffect, useState, useCallback } from "react";
import axios from "axios";

function usetGet(url, initialState, filterFn, mapFn) {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(initialState !== undefined ? initialState : {});

  function fetch() {
    setLoading(true);
    return axios
      .get(url)
      .then(({ data }) => (filterFn ? data.filter(filterFn) : data))
      .then(filteredData => (mapFn ? filteredData.map(mapFn) : filteredData))
      .then(setResponse)
      .finally(() => setLoading(false));
  }

  const refetch = useCallback(
    // eslint-disable-next-line consistent-return
    () => {
      if (!loading) return fetch();
    },
    [fetch]
  );

  useEffect(
    () => {
      fetch();
    },
    [url]
  );

  return [response, loading, refetch];
}

export default usetGet;
