import { useMemo, useCallback, useEffect } from "react";

export default function useScrollEnd(element, callback) {
  const scrollable = useMemo(
    () => {
      if (!element) return null;

      if (isScrollable(element)) return element;
      return getScrollableParent(element);
    },
    [element]
  );

  const onScroll = useCallback(
    e => {
      if (!callback) return;

      const { offsetHeight, scrollTop, scrollHeight } = e.srcElement;

      if (offsetHeight + scrollTop >= scrollHeight) callback();
    },
    [callback]
  );

  useEffect(
    () => {
      if (!scrollable) return;

      scrollable.addEventListener("scroll", onScroll);
      // eslint-disable-next-line consistent-return
      return () => scrollable.removeEventListener("scroll", onScroll);
    },
    [scrollable, onScroll]
  );
}

function isScrollable(ele) {
  const hasScrollableContent = ele.scrollHeight > ele.clientHeight;

  const overflowYStyle = window.getComputedStyle(ele).overflowY;
  const isOverflowHidden = overflowYStyle.indexOf("hidden") !== -1;

  return hasScrollableContent && !isOverflowHidden;
}

function getScrollableParent(ele) {
  if (!ele || ele === document.body) return document.body;

  return isScrollable(ele) ? ele : getScrollableParent(ele.parentNode);
}
