import useUsuario from "./useUsuario";

export default function useSelectPermission(funcionalidadeId) {
  const user = useUsuario();

  if (!user.permissoes) {
    return null;
  }

  return user.permissoes.find(p => p.funcionalidade === funcionalidadeId);
}
