import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "./_common/utils/removeExternalStyles.css";
import AppContainer from "./_common/containers/App";
import AppWrapper from "./_common/components/AppWrapper";
import Loading from "./_common/components/Loading";
import "./pages/telefonia/utils/PhoneDialer";
import "./utils/broadcast";
import store from "./store/store";
import DataFetchingConfig from "./_common/DataFetchingConfig/DataFetchingConfig";
import SnackProvider from "./_common/components/SnackProvider";
import localStorageManager from "./_common/utils/storage";
import { HABILITAR_DATADOG, INFOS_USUARIO, JWT_TOKEN } from "./_common/utils/storage/constantesStorage";
import Startup from "./_common/utils/startUpApplication/Startup";
import ErrorBoundary from "./ErrorBoundary";
import {
  addPropertyContextView,
  addUserInfosDatadogBrowserRum,
  isDatadogHabilitado,
  loadDatadogBrowserRum,
} from "./_common/utils/startUpApplication/analyticsProvider/datadogBrowserRum";

const addLogDataDog = async estaLogado => {
  try {
    const datadoHabilitado = await isDatadogHabilitado();
    localStorageManager.setItem(HABILITAR_DATADOG, datadoHabilitado);
    if (!datadoHabilitado) return;
    loadDatadogBrowserRum();
    addPropertyContextView("IsStartedFromFullRefresh", true);

    if (!estaLogado) return;
    const infosUsuario = localStorageManager.getItem(INFOS_USUARIO);

    const { empresaClienteId, email, id, nome } = infosUsuario;
    addUserInfosDatadogBrowserRum(id, email, nome, empresaClienteId);
  } catch (error) {
    console.log(error);
  }
};

const initializeApp = async () => {
  const infosUsuario = localStorageManager.getItem(JWT_TOKEN);
  const estaLogado = infosUsuario != null;
  addLogDataDog(estaLogado, infosUsuario);
  if (estaLogado) {
    await Startup();
  }
};

initializeApp().then(() => {
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <div style={{ position: "fixed", width: "100%", right: "20px", top: "15px", zIndex: "1500" }}>
          <div id="alertsContainer" style={{ position: "absolute", right: "0px", top: "90px" }} />
        </div>
        <div id="navbar" />
        <div id="snackbar" />
        <div id="dialogConfirm" />
        <DataFetchingConfig>
          <AppWrapper temRouter>
            <React.Suspense fallback={<Loading isLoading />}>
              <SnackProvider>
                <AppContainer />
              </SnackProvider>
            </React.Suspense>
          </AppWrapper>
        </DataFetchingConfig>
      </Provider>
    </ErrorBoundary>,
    document.getElementById("root")
  );
});
