import { useCallback } from "react";
import axios from "axios";
import useSWR from "swr";

const DEFAULT_OPTIONS = { callback: null, fallbackData: undefined, suffix: { get: "", post: "" } };

function useApi(url, prePost = v => v, settings = DEFAULT_OPTIONS) {
  const options = { ...DEFAULT_OPTIONS, ...settings };

  const { data, error, isValidating, mutate } = useSWR(`${url}${options.suffix.get}`, {
    revalidateOnMount: true,
    fallbackData: options.fallbackData,
  });

  const save = useCallback(
    form =>
      axios.post(`${url}${options.suffix.post}`, prePost(form)).then(({ data: success }) => {
        if (success) {
          mutate(form, false);
        }

        if (options.callback) {
          return options.callback(success);
        }

        return success;
      }),
    [prePost]
  );

  return [data, isValidating, save, mutate, error];
}

export default useApi;
