import { useSelector } from "react-redux";
import useSWR from "swr";

import { TIPO_PLANO_SPOTTER } from "../_common/constantes";
import localStorageManager from "../_common/utils/storage";
import { INFOS_USUARIO } from "../_common/utils/storage/constantesStorage";

function useTipoPlanoSpotter() {
  const usuario = useSelector(state => state.usuario);
  if (usuario.tipoPlano !== undefined) return usuario.tipoPlano === TIPO_PLANO_SPOTTER.FULL;
  return buscarTipoPlanoEmpresa(usuario) === TIPO_PLANO_SPOTTER.FULL;
}

function buscarTipoPlanoEmpresa(usuario) {
  const { data } = useSWR("/api/pipeline/Empresa/TipoPlano");
  const usuarioLogado = { ...usuario, tipoPlano: data };
  localStorageManager.setItem(INFOS_USUARIO, usuarioLogado);
  return data;
}

export default useTipoPlanoSpotter;
