import { useState } from "react";

function useOrderBy(initialKey, defaultAscending = true) {
  const [field, setField] = useState(initialKey);
  const [ascending, setAscending] = useState(defaultAscending);

  function sortBy(key) {
    setField(key);
    setAscending(key === field ? !ascending : true);
  }

  return [field, ascending, sortBy];
}

export default useOrderBy;
