import { useEffect, useState } from "react";
import axios from "axios";

function usePost(url, body) {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState([]);

  useEffect(
    () => {
      setLoading(true);
      axios
        .post(url, body)
        .then(({ data }) => setResponse(data))
        .finally(() => setLoading(false));
    },
    [url, body]
  );

  return [response, loading];
}

export default usePost;
