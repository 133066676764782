import useUsuario from "./useUsuario";

function useRolloutPermission(featureTogglesRota) {
  const { featureToggles } = useUsuario();

  const routeLiberadaParaTodosSemRollout = featureTogglesRota?.length === 0 || featureTogglesRota == null;
  const featureTogglesUsuario = featureToggles;

  return (
    routeLiberadaParaTodosSemRollout || featureTogglesRota.every(chave => featureTogglesUsuario[chave.nome] === true)
  );
}

export default useRolloutPermission;
