import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { LABELS_TIPO_IMPEDITIVO } from "../_common/constantes";

export default function useImpeditivos(impeditivos) {
  const intl = useIntl();
  const [operacoes, setOperacoes] = useState("");

  useEffect(
    () => {
      let op = "";
      if (impeditivos)
        impeditivos.forEach(impeditivo => {
          op = `${op ? `${op}, ` : ""}${intl.formatMessage(LABELS_TIPO_IMPEDITIVO[impeditivo])}`;
        });
      setOperacoes(op);
    },
    [impeditivos]
  );

  return operacoes;
}
