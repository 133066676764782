import { useMemo, useState, useEffect } from "react";

const PAGE_SIZE = 20;
export default function usePaginated(items) {
  const [page, setPage] = useState(1);
  const paged = useMemo(() => items.slice(0, page * PAGE_SIZE), [items, page]);

  useEffect(
    () => {
      setPage(1);
    },
    [items]
  );

  return [paged, page, setPage];
}
