import { useMemo } from "react";

import useUsuario from "./useUsuario";
import { hasPermission } from "../_common/permissions";

function usePermission(claim, lead, operator, checkIndividualOrGroupLevelWithoutleads) {
  const user = useUsuario();

  if (typeof claim === "boolean") return claim;

  return useMemo(
    () =>
      hasPermission(
        user,
        Array.isArray(claim) ? claim : [claim],
        Array.isArray(lead) ? lead : [lead],
        operator,
        checkIndividualOrGroupLevelWithoutleads
      ),
    [claim, lead]
  );
}

export default usePermission;
