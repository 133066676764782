import { useMemo } from "react";
import { useLocation } from "react-router";

function useQueryParams() {
  const { search } = useLocation();

  return useMemo(
    () =>
      search
        .replace("?", "")
        .split("&")
        .reduce((query, param) => {
          const [name, value] = param.split("=");
          return { ...query, [name]: value };
        }, {}),
    [search]
  );
}

export default useQueryParams;
