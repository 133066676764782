import { useState, useEffect } from "react";
import axios from "axios";
import { CachedRequests } from "../_common/utils/cachedRequests";

const CONFIG_URL = "/api/pipeline/ConfiguracoesCep/Buscar";
const cepCache = {};

export default function useCepService() {
  const [isValidating, setIsValidating] = useState(false);
  const [meta, setMeta] = useState({ isLoading: false, url: "", pwd: "", error: null });

  const getConfig = async () => {
    try {
      setMeta({ ...meta, isLoading: true });
      const config = await CachedRequests.get(CONFIG_URL, () => axios.get(CONFIG_URL).then(({ data }) => data));
      setMeta({ ...meta, isLoading: false, url: config.endereco, pwd: config.senha });
    } catch (err) {
      setMeta({ ...meta, isLoading: false, error: err });
      // eslint-disable-next-line
      console.error(err);
    }
  };

  useEffect(() => {
    getConfig();
  }, []);

  const getCep = async (cep = "") => {
    if (meta.error) {
      // eslint-disable-next-line
      console.error(meta.error);
      return null;
    }

    const normalizedCep = cep
      .toString()
      .replace(/[- ]+/, "")
      .trim();

    if (cepCache[normalizedCep]) {
      return cepCache[normalizedCep];
    }

    setIsValidating(true);
    const headers = { pwd: meta.pwd };
    try {
      const { data } = await axios.get(`${meta.url}${normalizedCep}`, { headers });
      cepCache[normalizedCep] = data;
    } catch (err) {
      cepCache[normalizedCep] = null;
      throw err;
    } finally {
      setIsValidating(false);
    }

    return cepCache[normalizedCep];
  };

  return [getCep, isValidating, meta];
}
