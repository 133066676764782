import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { CachedRequests } from "../_common/utils/cachedRequests";

function usetGetCached(url, initialState) {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState(initialState !== undefined ? initialState : {});
  const [error, setError] = useState(null);

  async function fetch() {
    setIsLoading(true);
    try {
      const resp = await CachedRequests.get(url, () => axios.get(url).then(({ data }) => data));
      setResponse(resp);
      setError(null);
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }

  const invalidateUrl = () => CachedRequests.invalidateUrl(url);

  const refetch = useCallback(
    // eslint-disable-next-line consistent-return
    () => {
      if (!isLoading) {
        fetch();
      }
    },
    [fetch]
  );

  useEffect(
    () => {
      fetch();
    },
    [url]
  );

  return { response, isLoading, error, refetch, invalidateUrl };
}

export default usetGetCached;
