import useUsuario from "./useUsuario";

function usePerfilPermission(roles) {
  const user = useUsuario();

  const rolesRoute = roles;
  const routeLiberadaParaTodosPerfis = rolesRoute?.length === 0 || rolesRoute == null;
  const rolesUsuario = user?.roles;

  return (
    routeLiberadaParaTodosPerfis ||
    (rolesRoute && rolesUsuario && rolesUsuario?.some(role => rolesRoute?.includes(role)))
  );
}

export default usePerfilPermission;
