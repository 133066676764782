import { useMemo } from "react";

import useUsuario from "./useUsuario";
import { hasPermissionSimple } from "../_common/permissions";

function usePermissionSimple(claim, lead, operator) {
  const user = useUsuario();

  if (typeof claim === "boolean") return claim;

  return useMemo(() => hasPermissionSimple(user, Array.isArray(claim) ? claim : [claim], operator), [claim, lead]);
}

export default usePermissionSimple;
